<template>
	<div id="product">
		<div>
			<div class="title">
				<h2><span>희망거래로 <br /><em>좀 더 싸게 구매해보세요!</em></span></h2>
			</div>
			<div class="top" style="margin-left: 25px; margin-right: 25px;">
				<div class="left">
					<div class="swiper_01">
						<swiper class="swiper-wrapper" :options="swiperOptions">
							<swiper-slide class="swiper-slide">
								<img :src="goods_thumbnail" alt="썸네일">
							</swiper-slide>
						</swiper>
						<!-- <div class="swiper-pagination"></div> -->
					</div>
				</div>
				<div class="right">
					<div class="info_01">
						<p>{{goods.goods_name}}</p>
						<ul>
							<!-- <li><a href="javascript:"><img src="../assets/common/ico-36-wish.svg" alt="찜하기"></a></li>
							<li><a href="javascript:" @click="showShare = !showShare"><img src="../assets/common/ico-36-share.svg" alt="공유하기"></a></li> -->
						</ul>
						<transition name="fade">
							<div v-if="showShare" class="share">
								<div>
									<p>Copy Link</p>
									<span>{{url}}</span>
								</div>
								<div>
									<p>Share</p>
									<ul>
										<li><a href="javascript:"><img src="../assets/common/share-01.png" alt="카카오톡"></a></li>
										<li><a href="javascript:"><img src="../assets/common/share-02.png" alt="페이스북"></a></li>
										<li><a href="javascript:"><img src="../assets/common/share-03.png" alt="트위터"></a></li>
										<li><a href="javascript:"><img src="../assets/common/share-04.png" alt="인스타그램"></a></li>
									</ul>
								</div>
							</div>
						</transition>
					</div>
					<div class="info_02">
						<dl>
							<dt>재고 수량</dt>
							<dd>{{goods.goods_qty - goods.sales_sum_qty }}개</dd>
						</dl>
						<dl>
							<dt>종료일</dt>
							<dd>{{goods.end_date}}</dd>
						</dl>
						<dl>
							<dt>판매 가격</dt>
							<dd>{{goods.goods_price | makeComma}}원</dd>
						</dl>
						<!-- <p class="select">
							<select>
								<option>옵션선택</option>
							</select>
						</p>
						<div class="price">
							<p class="left">
								<a href="javascript:" class="minus">-</a>
								<input type="text" value="1" maxlength="2">
								<a href="javascript:" class="plus">+</a>
							</p>
							<p class="right"><span>총 금액</span>547,000원</p>
						</div> -->
					</div>
					<div class="btn" style="display: inline;">
						<a href="javascript:" @click="payNow">바로구매</a>
						<a href="javascript:" @click="showPopup = true">희망거래</a>
					</div>
				</div>
			</div>
			<div class="bottom">
				<div class="section section_01" :class="{ active: toogle_01 }">
					<div class="toogle">
						<p>상세정보</p>
						<a href="javascript:" @click="toogle_01 = !toogle_01"></a>
					</div>
					<div class="content">
						<div class="content_inner">
							<!-- <img src="../assets/data/sample.png" alt="상세정보"> -->
							<img v-for="(detail, index) in imgList" :src="detail" :key="index">
						</div>
					</div>
				</div>
				<div class="section section_02" :class="{ active: toogle_02 }">
					<div class="toogle">
						<p>배송 및 교환/반품 안내</p>
						<a href="javascript:" @click="toogle_02 = !toogle_02"></a>
					</div>
					<div class="content">
						<div class="content_inner">
							<dl>
								<dt>배송 관련</dt>
								<dd>결제 확인 후 1~3일 정도 소요됩니다.</dd>
							</dl>
							<dl>
								<dt>교환 및 반품</dt>
								<dd>결제 확인 후 1~3일 정도 소요됩니다.</dd>
								<dd>택배비 : 왕복 5,000원(사유가 고객에게 있는 경우에만 발생 ex:사이즈, 단순 변심 등)</dd>
								<dd>배송 완료일 이후 최대 7일 이내 신청 가능</dd>
								<dd>배송중에는 교환 및 반품 불가</dd>
								<dd>상품을 사용 혹은 훼손한 경우 교환 및 반품 불가</dd>
							</dl>
						</div>
					</div>
				</div>
			</div>
		</div>

		<transition name="fade">
			<div v-if="showPopup" id="popup">
				<div id="black" @click="showPopup = false"></div>
				<div class="popup_inner">
					<a href="javascript:" @click="showPopup = false"><img src="../assets/common/ico-44-modal-close.svg" alt="닫기"></a>
					<p class="title">원하는 가격을 잘 선택하세요.<br><em>기회는 한 번밖에 없습니다.</em></p>
					<div>
						<div class="left">
							<div class="swiper_02">
								<ul class="swiper-wrapper">
									<li class="swiper-slide">
										<img :src="imageSrc + goods.goods_thumbnail" alt="썸네일">
									</li>
								</ul>
								<!-- <div class="swiper-pagination"></div> -->
							</div>
						</div>
						<div class="right">
							<div class="info_01">
								<p>{{goods.goods_name}}</p>
							</div>
							<div class="info_02">
								<dl>
									<dt>재고 수량</dt>
									<dd>{{goods.goods_qty - goods.sales_sum_qty }}개</dd>
								</dl>
								<dl>
									<dt>종료일</dt>
									<dd>{{goods.end_date}}</dd>
								</dl>
								<dl>
									<dt>바로 구매 가능한 금액</dt>
									<dd>{{goods.goods_price | makeComma}}원</dd>
								</dl>
							</div>
							<div class="btn" style="margin-top: 0px; width: 100%;">
								<form>
									<input type="text" placeholder="희망하는 가격을 입력하세요." v-model="userDeal" @keyup="dealChange">
									<input type="button" value="GO!" v-if="isWant" :disabled="!isWant" @click="checkDeal(true)">
									<input type="button" value="GO!" v-if="!isWant" :disabled="!isWant" @click="checkDeal(false)" style="background-color: #6c757d;">
								</form>
								<div>
									<p>다른분들의 <span>희망가격</span> 입니다.</p>
									<div v-if="wantHistory.length > 0">
										<dl v-for="(item, index) in wantHistory" :key="index" >
											<d style="margin-right: 18px;">{{item.deal_price | makeComma}}원</d>
											<dd>{{item.use_date}}</dd>
										</dl>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>


<script>
import { Swiper,directive, SwiperSlide } from 'vue-awesome-swiper'
import {postData, showToast} from '../api/serverApi';
import moment from "moment";
import BootPay from "bootpay-js";
import localKeystore from '../local/localKeystore.js'

export default {
	components: {
		Swiper,
		SwiperSlide
	},
	directives: {
		swiper: directive
	},
	data() {
		return {
			showShare: false,
			showPopup: false,
			toogle_01: false,
			toogle_02: false,
			swiperOptions: {
				slidesPerView: 1,
				loop: false,
				spaceBetween: 24,
				slidesOffsetAfter : 0, 
				pagination: {
					el: '.swiper-pagination',
					type: 'bullets',
					clickable: true,
				},
			},
			nowTime: null,
			user: {},
			imageSrc: '',
			wantHistory: [],
      		isWant: false,
			goods: {},
			url:window.location.href,
      		userDeal: '',
			imgList:[],
			goods_thumbnail:'',
			bootpayWindows: null,
		}
	},
	directives: {
		swiper: directive
	},
	mounted() {
		console.log('detail', this.$route, this.$route.params.eventCode);

		this.user = localStorage.getItem('user-info');
		console.log(['user', this.user]);
		this.nowTime = moment(new Date()).format('Y.MM.D HH:mm:ss');
		console.log(['nowTime', this.nowTime]);

		// let IMG_URL = 'https://api.wonhago.com';
		let IMG_URL = process.env.VUE_APP_API_URL;

		if (process.env.NODE_ENV === 'development') {
			// IMG_URL = 'http://localhost:3004';
			IMG_URL = 'https://stgapi.wonhago.com';
		}

		this.imageSrc = IMG_URL;

		this.getDetail();
	},
	methods: {
		getDetail() {//제품 상세 정보 조회
			this.$log.debug(['product detail 라우터 파람', this.$route.params])
			if (this.$route.params && this.$route.params.goodsCode) {
				const goodsCode = this.$route.params.goodsCode;
				const user = localStorage.getItem('user-info');
				const param = {
					goods_code: goodsCode,
					userInfo : JSON.parse(user),
				}
				this.$log.debug(['user', user])

				postData('/user/goodsDetail', param).then( res => {
					this.$log.debug(['getGoods', res]);
					if (res.data.success) {
						this.goods = res.data.goods[0];
						this.goods_thumbnail = this.imageSrc + res.data.goods[0].goods_thumbnail;
						this.$log.debug(['this.goods.detail_count', this.goods.detail_count]);
						this.isWant = res.data.want;
						this.wantHistory = res.data.want_history;

						this.imgList = [];
						for(let i = 0; i < this.goods.detail_count ;i++){
							this.$log.debug(['i', i]);
							this.imgList.push(this.imageSrc + this.goods.image_path + 'detail'+(i+1)+this.goods.detail_ext);
						}
						this.$log.debug(['imgList', this.imgList]);

					}
				})
			}
		},
		addComma: function (val) {
			return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		},
		dealChange: function (e) {
			// String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
			this.$log.debug(['dealChange', e.target.value, this.addComma(e.target.value)]);
			this.userDeal = this.addComma(e.target.value.replace(/,/g, ""));
		},
		checkDeal(dealUse) {//희망거래 
			const pinNumberKey = 'WNS_PIN_NUMBER'; //키값으로 로컬 스토리지에 저정
			let address = localKeystore.getAddress();
			console.log(['address', address]);
			if (!address) { 
				alert("지갑 생성후에 참여 가능합니다. 지갑 생성 페이지로 이동합니다.");
				this.$router.push('/join'); 
				return;
			} else {
				const pinNumber = sessionStorage.getItem(pinNumberKey)
				if (!pinNumber) {
					console.log('not found pin number')
					alert("로그인후에 참여 가능합니다. 로그인 페이지로 이동합니다.");
					this.$router.push({path:'/login'})
				return;
				} 
			}

			let dealPrice = 0;
			if(!dealUse){
				showToast('이 상품은 이미 희망거래를 하셨습니다.');
			}
			console.log("checkDeal");
			this.$log.debug(['useDeal', this.userDeal, this.goods]);
			this.$log.debug(['useDeal', String(this.userDeal).replace(",",""), this.goods]);
			
			if (!this.userDeal) {
				showToast('희망하는 금액을 입력하세요.');
			} else if (this.goods.goods_price <= String(this.userDeal).replace(",","")) {
				showToast('상품가격과 같거나 큰 금액을 입력하셨습니다.');
			} else {
				dealPrice = String(this.userDeal).replace(",","");
				this.goods.deal_price = String(this.userDeal).replace(",","");
				// 사용자 정보를 가져온다.
				const user = localStorage.getItem('user-info');
				// this.$log.debug(['user', user]);
				let userInfo = null;
				if (user) {
					userInfo = JSON.parse(user);
					this.goods.user_code = userInfo.user_code;
				}
				console.log(['userInfo', userInfo]);

				postData('/user/postDealCheck', this.goods).then( res => {
					this.$log.debug(['postDealCheck result', res]);

					console.log(['deal 결과 1', res.data]);
					this.getDetail();
					this.wantHistory = res.data.want_history;

					if (res.data.success) {
						
						const settingsObject = {
							singular: false, 
							position: 'center-center', 
							theme: 'light', 
							iconEnabled: false, 
							hideProgressbar: true, 
							withBackdrop: true
						}

						this.$vToastify.setSettings(settingsObject);

						this.$vToastify.prompt({
							body: res.data.reason + '<br>' + this.addComma(this.goods.deal_price) + '원에 구매하시겠습니까?',
							answers: { "바로구매": true, "닫기": false }
						}).then( value => {
							console.log(['prompt', value]);
							if (value) {
								//결제요!!
								console.log(['this.goods.deal_price', this.goods.deal_price]);
								console.log(['dealPrice', dealPrice]);

								this.bootpay(dealPrice, true, this.goods.goods_code);
								// this.pay(dealPrice, true);

								// showToast('희망거래에 성공하셨습니다. 결제 ', 'success');
							}
						});
						// nodeApi.showToast('희망거래 성공', 'success');

					} else {
						console.log(['deal 실패', res.data.reason]);
						showToast(res.data.reason);
						
					}
				}).catch( err => {
					showToast('희망거래 오류가 발생했습니다.');
					this.$log.error(['postDealCheck result', err]);
				})
			}
		},
		payNow: function () {
			// this.pay(this.goods.goods_price, false);
			this.bootpay(this.goods.goods_price, false, this.goods.goods_code );
		},
		bootpay(priceDeal, wantDeal, goodCode) {
			console.log("bootpay");
			console.log(["priceDeal", priceDeal]);
			console.log(["this.goods.deal_price", this.goods.deal_price]);
			console.log(["wantDeal", wantDeal]);
			this.bootpayWindows = BootPay.request({
				// price: this.totalPayment, //실제 결제되는 가격
				price: priceDeal, //테스트 시 결제가격
				application_id: "5d6dd9c90627a80037aeca3d",
				name: this.goods.goods_name, //결제창에서 보여질 이름
				pg: 'payapp',
				method: 'card', //결제수단, 입력하지 않으면 결제수단 선택부터 화면이 시작합니다.
				show_agree_window: 0, // 부트페이 정보 동의 창 보이기 여부
				user_info: {
				username: '',
				// email: '사용자 이메일',
				// addr: '사용자 주소',
				phone: '01012345678'
				},
				order_id: Date.now(), //고유 주문번호로, 생성하신 값을 보내주셔야 합니다.
				params: {callback1: '그대로 콜백받을 변수 1', callback2: '그대로 콜백받을 변수 2', wantDeal: wantDeal, goods_code: goodCode},
				account_expire_at: '2020-10-25', // 가상계좌 입금기간 제한 ( yyyy-mm-dd 포멧으로 입력해주세요. 가상계좌만 적용됩니다. )
				// extra: {
				//   start_at: '2019-05-10', // 정기 결제 시작일 - 시작일을 지정하지 않으면 그 날 당일로부터 결제가 가능한 Billing key 지급
				//   end_at: '2022-05-10', // 정기결제 만료일 -  기간 없음 - 무제한
				//   vbank_result: 1, // 가상계좌 사용시 사용, 가상계좌 결과창을 볼지(1), 말지(0), 미설정시 봄(1)
				//   quota: '0,2,3', // 결제금액이 5만원 이상시 할부개월 허용범위를 설정할 수 있음, [0(일시불), 2개월, 3개월] 허용, 미설정시 12개월까지 허용,
				//   theme: 'purple', // [ red, purple(기본), custom ]
				//   custom_background: '#00a086', // [ theme가 custom 일 때 background 색상 지정 가능 ]
				//   custom_font_color: '#ffffff' // [ theme가 custom 일 때 font color 색상 지정 가능 ]
				// }
			}).error(function (data) {
				//결제 진행시 에러가 발생하면 수행됩니다.
				console.log(["error",data]);
			}).cancel(function (data) {
				//결제가 취소되면 수행됩니다.
				console.log(["cancel",data]);
			}).ready(function (data) {
				// 가상계좌 입금 계좌번호가 발급되면 호출되는 함수입니다.
				console.log(["ready",data]);
			}).confirm(function (data) {
				//결제가 실행되기 전에 수행되며, 주로 재고를 확인하는 로직이 들어갑니다.
				//주의 - 카드 수기결제일 경우 이 부분이 실행되지 않습니다.
				console.log(['pay confirm', data]);
				var enable = true; // 재고 수량 관리 로직 혹은 다른 처리
				if (enable) {
					BootPay.transactionConfirm(data); // 조건이 맞으면 승인 처리를 한다.
				} else {
					BootPay.removePaymentWindow(); // 조건이 맞지 않으면 결제 창을 닫고 결제를 승인하지 않는다.
				}
			}).close(function (data) {
				// 결제창이 닫힐때 수행됩니다. (성공,실패,취소에 상관없이 모두 수행됨)
				console.log(["close",data]);
			}).done(function (datas) {
				//결제가 정상적으로 완료되면 수행됩니다
				//비즈니스 로직을 수행하기 전에 결제 유효성 검증을 하시길 추천합니다.
				console.log(['pay done', datas]);
				// this.pays(data.amount, data.params.wantDeal, data)

				let price = datas.amount;
				let wantDeal = datas.params.wantDeal;
				let purchase_data = datas;

				console.log(['pay price', price]);
				// console.log(['pay this.goods.deal_price', this.goods.deal_price]);
				console.log(['pay wantDeal', wantDeal]);
				let purchaseInfo = {
					purchase_name : purchase_data.item_name,		//주문시 표기된 항목
					purchase_sum_qty : 1,	//주문합계수량
					deal_price : price,		//주문가격
					receiver : '',			//받는사람
					email : '',				//이메일
					phone : '',				//연락처
					zipCode : '',			//우편번호
					address : '',			//배송지 주소
					addressEtc : '',		//상세주소
					delivery_message : '',	//배송시요청사

					want_deal : wantDeal,			//희망거래 유무
					goods_code : datas.params.goods_code,		//상품코드
					qty : 1,				//수량
					use_stock : true,			//재고 여부
					deliveryType : '',
					goods_price : price,		//상품가격
				}

				const user = localStorage.getItem('user-info');
					// this.$log.debug(['user', user]);
				let userInfo = null;
				if (user) {
					userInfo = JSON.parse(user);
				}

				let pgInfo = {
					item_name : purchase_data.item_name,	//상품명
					receipt_id : purchase_data.receipt_id,//결제키
				}


				// let order_id = Date.now().toString(); 
				const data = { 
					data: {
						receipt_id: purchase_data.receipt_id,
					order_id: purchase_data.order_id,
					name: purchase_data.item_name,
					price: purchase_data.amount,
					unit: 'krw',
					pg: purchase_data.pg,
					method: purchase_data.method,
					pg_name: purchase_data.pg_name,
					method_name: purchase_data.method_name,
					payment_data:
						{ 
							card_name: purchase_data.card_name,
							card_no: purchase_data.card_no,
							card_quota: purchase_data.card_quota,
							card_auth_no: '',
							receipt_id: purchase_data.receipt_id,
							n: purchase_data.item_name,
							p: price,
							tid: '',
							pg: purchase_data.pg_name,
							pm: purchase_data.method_name,
							pg_a: purchase_data.pg,
							pm_a: purchase_data.method,
							o_id: purchase_data.order_id,
							p_at: purchase_data.purchased_at,
							s: 1,
							g: 2 
						},
					requested_at: purchase_data.requested_at,
					purchased_at: purchase_data.purchased_at,
					status: purchase_data.status,
					status_en: 'complete',
					status_ko: '결제완료' 
					},
				};

				postData('/user/purchaseTemp', { purchaseInfo : purchaseInfo, userInfo : userInfo, pgInfo : pgInfo, data : data } ).then( res => {
					console.log(['purchaseTemp result', res]);

					if (res.data.success) {
						showToast('구매에 성공하셨습니다. 지갑에서 확인하세요.', 'success');
						//this.$router.push('/wallet');

						// const settingsObject = {
						// 	singular: false, 
						// 	position: 'center-center', 
						// 	theme: 'light', 
						// 	iconEnabled: false, 
						// 	hideProgressbar: true, 
						// 	withBackdrop: true
						// }

						// this.$vToastify.setSettings(settingsObject);

						// this.$vToastify.prompt({
						// 	body: '구매에 성공하셨습니다. 지갑으로 이동하여 확인하시겠습니까?',
						// 	answers: { "지갑으로 이동": true, "닫기": false }
						// }).then( value => {
						// 	this.$router.push('/wallet');
						// });

						// if (confirm("구매에 성공하셨습니다. 지갑으로 이동하여 확인하시겠습니까?")) {
						// 	parent.$router.push('/wallet');
						// }
					}else{
						showToast('거래 오류가 발생했습니다.');
					}
					
				}).catch( err => {
					console.log(['purchaseTemp result catch', err]);
					showToast('거래 오류가 발생했습니다.');
					// this.$log.error(['purchaseTemp result', err]);
				})

			});
			console.log(['bootpay']);
		},
		pays(price, wantDeal, purchase_data){
			console.log(['pay price', price]);
			console.log(['pay this.goods.deal_price', this.goods.deal_price]);
			console.log(['pay wantDeal', wantDeal]);
			let purchaseInfo = {
				purchase_name : this.goods.goods_name,		//주문시 표기된 항목
				purchase_sum_qty : 1,	//주문합계수량
				deal_price : price,		//주문가격
				receiver : '',			//받는사람
				email : '',				//이메일
				phone : '',				//연락처
				zipCode : '',			//우편번호
				address : '',			//배송지 주소
				addressEtc : '',		//상세주소
				delivery_message : '',	//배송시요청사

				want_deal : wantDeal,			//희망거래 유무
				goods_code : this.goods.goods_code,		//상품코드
				qty : 1,				//수량
				use_stock : true,			//재고 여부
				deliveryType : '',
				goods_price : this.goods.goods_price,		//상품가격
			}

			const user = localStorage.getItem('user-info');
				// this.$log.debug(['user', user]);
			let userInfo = null;
			if (user) {
				userInfo = JSON.parse(user);
			}

			let pgInfo = {
				item_name : this.goods.goods_name,	//상품명
				receipt_id : purchase_data.receipt_id,//결제키
			}


			// let order_id = Date.now().toString(); 
			const data = { 
				data: {
					receipt_id: purchase_data.receipt_id,
                order_id: purchase_data.order_id,
                name: this.goods.goods_name,
                price: purchase_data.amount,
                unit: 'krw',
                pg: purchase_data.pg,
                method: purchase_data.method,
                pg_name: purchase_data.pg_name,
                method_name: purchase_data.method_name,
                payment_data:
                    { 
						card_name: purchase_data.card_name,
                        card_no: purchase_data.card_no,
                        card_quota: purchase_data.card_quota,
                        card_auth_no: '',
                        receipt_id: purchase_data.receipt_id,
                        n: this.goods.goods_name,
                        p: price,
                        tid: '',
                        pg: purchase_data.pg_name,
                        pm: purchase_data.method_name,
                        pg_a: purchase_data.pg,
                        pm_a: purchase_data.method,
                        o_id: purchase_data.order_id,
                        p_at: purchase_data.purchased_at,
                        s: 1,
                        g: 2 
					},
                requested_at: purchase_data.requested_at,
                purchased_at: purchase_data.purchased_at,
                status: purchase_data.status,
                status_en: 'complete',
                status_ko: '결제완료' 
				},
			};

			postData('/user/purchaseTemp', { purchaseInfo : purchaseInfo, userInfo : userInfo, pgInfo : pgInfo, data : data } ).then( res => {
				this.$log.debug(['purchaseTemp result', res]);

				if (res.data.success) {
					showToast('구매에 성공하셨습니다.', 'success');
					//this.$router.push('/wallet');

					const settingsObject = {
						singular: false, 
						position: 'center-center', 
						theme: 'light', 
						iconEnabled: false, 
						hideProgressbar: true, 
						withBackdrop: true
					}

					this.$vToastify.setSettings(settingsObject);

					this.$vToastify.prompt({
						body: '구매에 성공하셨습니다. 지갑으로 이동하여 확인하시겠습니까?',
						answers: { "지갑으로 이동": true, "닫기": false }
					}).then( value => {
						this.$router.push('/wallet');
					});

				}else{
					showToast('거래 오류가 발생했습니다.');
				}
				
			}).catch( err => {
				showToast('거래 오류가 발생했습니다.');
				this.$log.error(['purchaseTemp result', err]);
			})
		}
	}
}
</script>